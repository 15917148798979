// src/Components/View.js
import React, { useState, useEffect } from 'react';
import CharacterController from './CharacterController';
import InfoPanel from './InfoPanel';
import Education from './Education';
import Experience from './Experience';
import ContactMe from './ContactMe';
import '../Style/View.css';

const View = () => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuSelect = (menu) => {
    console.log('View.js: handleMenuSelect => selected menu:', menu);
    setSelectedMenu(menu);
  };

  const handleClosePanel = () => {
    console.log('View.js: handleClosePanel => setSelectedMenu(null)');
    setSelectedMenu(null);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'about':
        return <InfoPanel onClose={handleClosePanel} />;
      case 'education':
        return <Education onClose={handleClosePanel} />;
      case 'experience':
        return <Experience onClose={handleClosePanel} />;
      case 'contact':
        return <ContactMe onClose={handleClosePanel} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const root = document.body;
    if (selectedMenu) {
      root.classList.add("show-cursor");
    } else {
      root.classList.remove("show-cursor");
    }
  }, [selectedMenu]);

  return (
    <div className="view-container">
      <CharacterController onMenuSelect={handleMenuSelect} />
      {selectedMenu && <div className="panel-container">{renderContent()}</div>}
    </div>
  );
};

export default View;
