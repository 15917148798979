// src/Components/InfoPanel.js
import React, { useEffect, useState } from 'react';
import '../Style/InfoPanel.css';
import AryaImg from '../images/Arya.jpg';

const InfoPanel = ({ onClose }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = AryaImg;
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className="jungle-about-wrapper">
      <button className="close-btn" onClick={onClose}>✕</button>

      <div className="about-content">
        <img
          src={AryaImg}
          alt="Arya Eisa"
          className={`profile-img ${imageLoaded ? 'loaded' : ''}`}
        />

        <div className="text-info">
          <h2> Hi, I’m Arya</h2>
          <p>
            Welcome to the heart of the jungle. I’m an engineer driven by curiosity, courage and building things that actually matter. But beyond code — I lead, adapt and execute.
          </p>
          <p>
            As the founder of <strong>Ophelia</strong>, I led a multidisciplinary team including a COO, CTO, AI developer and Cloud Engineer. Together, we built an AI-driven platform that uncovers psychological violence with empathy and precision.
          </p>
          <p>
            In my role as <strong>CEO of Threesoft AB</strong>, I guide the company’s strategic direction while staying deeply involved in development. I often step into the trenches — writing code, debugging, and adapting to whatever tech stack is used in the moment, from Kotlin and Swift to Python, React and C++.
          </p>
          <p>
            Leadership for me means being hands-on, supportive and never above the mission. Whether I’m coordinating across roles or diving into backend or frontend logic, I move with purpose — and I make sure the team does too.
          </p>
          <p>
            Whether you found this place by accident or destiny — I’m glad you're here. Let’s connect.
          </p>
          <a href="./AryaCV.pdf" download className="download-btn">
            🧾 Download My CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
