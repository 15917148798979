import React, { useState, useEffect, useRef, useMemo } from 'react';
import '../Style/CharacterController.css';

const CharacterController = ({ onMenuSelect, paused }) => {
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: 150 });
  const [trail, setTrail] = useState([]);
  const [highlighted, setHighlighted] = useState(null);

  const aboutRef = useRef(null);
  const eduRef = useRef(null);
  const expRef = useRef(null);
  const contactRef = useRef(null);

  const menuRefs = useMemo(() => ({
    about: aboutRef,
    education: eduRef,
    experience: expRef,
    contact: contactRef,
  }), []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (paused) return;
      const pos = { x: e.clientX, y: e.clientY };
      setPosition(pos);
      setTrail(prev => [...prev.slice(-12), pos]);
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [paused]);

  useEffect(() => {
    const checkOverlap = () => {
      let matched = null;
      Object.entries(menuRefs).forEach(([key, ref]) => {
        const el = ref.current;
        if (el) {
          const rect = el.getBoundingClientRect();
          const charLeft = position.x - 20;
          const charRight = position.x + 20;
          const charTop = position.y - 20;
          const charBottom = position.y + 20;

          if (
            charLeft < rect.right &&
            charRight > rect.left &&
            charTop < rect.bottom &&
            charBottom > rect.top
          ) {
            matched = key;
          }
        }
      });
      setHighlighted(matched);
    };
    checkOverlap();
  }, [position, menuRefs]);

  return (
    <div className="character-container">
      <header className="portfolio-header">
        <nav className="nav-links">
          <div
            ref={aboutRef}
            className={`nav-item about ${highlighted === 'about' ? 'active' : ''}`}
            onClick={() => onMenuSelect('about')}
          >
            <span>About Me</span>
          </div>
          <div
            ref={eduRef}
            className={`nav-item education ${highlighted === 'education' ? 'active' : ''}`}
            onClick={() => onMenuSelect('education')}
          >
            <span>Education</span>
          </div>
          <div
            ref={expRef}
            className={`nav-item experience ${highlighted === 'experience' ? 'active' : ''}`}
            onClick={() => onMenuSelect('experience')}
          >
            <span>Experience</span>
          </div>
          <div
            ref={contactRef}
            className={`nav-item contact ${highlighted === 'contact' ? 'active' : ''}`}
            onClick={() => onMenuSelect('contact')}
          >
            <span>Contact Me</span>
          </div>
        </nav>
      </header>

      {trail.map((t, i) => (
        <div
          key={i}
          className="trail-dot"
          style={{
            left: t.x,
            top: t.y,
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: (i + 1) / trail.length,
            animationDelay: `${i * 20}ms`
          }}
        />
      ))}

      <footer className="footer-info bottom-footer">
        <p>
          Made with 💻 by Arya <br />
          <a href="https://www.linkedin.com/in/arya-eisa-292710191/" target="_blank" rel="noreferrer">LinkedIn</a> |
          <a href="https://github.com/aryapoureisa" target="_blank" rel="noreferrer"> GitHub</a>
        </p>
      </footer>
    </div>
  );
};

export default CharacterController;
